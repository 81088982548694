'use client';

import { Link, Unstable_Grid2 as Grid2, Box } from '@mui/material';
import { useIsMobile } from '@qb/frontend/hooks/mediaQueries';
import { UmbrellaButton } from '@/app/(index)/_components/_components/UmbrellaButton';
import { ReactComponent as Logo } from './_assets/logo.svg';
import { HeaderMobile } from './_components/HeaderMobile';
import { MenuContainer } from './_components/MenuContainer';
export const Header = () => {
  const isMobile = useIsMobile();
  const headerPlaceholder = <Box data-cy="header-placeholder" sx={{
    width: '100%',
    height: {
      xs: 48,
      md: 62,
      lg: 82
    }
  }} />;
  if (isMobile) {
    return <>
        {headerPlaceholder}
        <HeaderMobile />
      </>;
  }
  return <>
      {headerPlaceholder}
      <Grid2 container alignItems="center" sx={{
      py: {
        xs: 1,
        lg: 2.25
      },
      position: 'fixed',
      zIndex: 'appBar',
      top: 0,
      width: '100%',
      bgcolor: 'common.white',
      background: 'rgba(255, 255, 255, 0.92)',
      backdropFilter: 'blur(5px)'
    }} data-sentry-element="Grid2" data-sentry-source-file="Header.tsx">
        <Grid2 xs={12} md="auto" lg={3} sx={{
        pl: {
          xs: 1,
          md: 1,
          lg: 3.375
        }
      }} data-sentry-element="Grid2" data-sentry-source-file="Header.tsx">
          <Link href="/" title="Go to Quotebeam homepage" sx={{
          height: 46,
          display: 'flex'
        }} data-sentry-element="Link" data-sentry-source-file="Header.tsx">
            <Logo height="100%" data-sentry-element="Logo" data-sentry-source-file="Header.tsx" />
          </Link>
        </Grid2>
        <Grid2 xs={12} md="auto" lg={6} sx={{
        display: 'flex',
        justifyContent: 'center',
        flexGrow: {
          xs: 1,
          lg: 0
        },
        gap: {
          xs: 0,
          lg: 0.5
        }
      }} data-sentry-element="Grid2" data-sentry-source-file="Header.tsx">
          <MenuContainer data-sentry-element="MenuContainer" data-sentry-source-file="Header.tsx" />
        </Grid2>
        <Grid2 xs={12} md="auto" lg={3} sx={{
        pr: {
          xs: 1,
          md: 1,
          lg: 6.125
        },
        display: 'flex',
        justifyContent: 'end',
        gap: {
          xs: 1,
          lg: 2.25
        }
      }} data-sentry-element="Grid2" data-sentry-source-file="Header.tsx">
          <UmbrellaButton size="small" variant="outlined" href="/get-a-demo" sx={{
          px: 2
        }} data-sentry-element="UmbrellaButton" data-sentry-source-file="Header.tsx">
            Talk to Sales
          </UmbrellaButton>
          <UmbrellaButton size="small" variant="contained" href="/get-a-demo" sx={{
          px: 2
        }} data-sentry-element="UmbrellaButton" data-sentry-source-file="Header.tsx">
            Get a Demo
          </UmbrellaButton>
        </Grid2>
      </Grid2>
    </>;
};