import { forwardRef } from 'react';
import { Inter } from 'next/font/google';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import { createBaseThemeOptions } from '@qb/base-theme/base-theme';
import { integrateThemeWithRouter, mergeCssVarsThemeOptions, generateBreakpointsCssVars } from '@qb/base-theme/base-theme-utils';
const LinkBehaviour = forwardRef<HTMLAnchorElement, NextLinkProps>(function LinkBehaviour(props, ref) {
  return <NextLink ref={ref} {...props} />;
});
const inter = Inter({
  subsets: ['latin']
});
const fontFamily = `${inter.style.fontFamily},Helvetica Neue,Helvetica,Arial,sans-serif`;
const baseTheme = createBaseThemeOptions({}, fontFamily);
export const marketingThemeOptions = mergeCssVarsThemeOptions(baseTheme, integrateThemeWithRouter(LinkBehaviour), generateBreakpointsCssVars(), {
  colorSchemes: {
    light: {
      palette: {
        background: {
          default: '#FFFFFF'
        }
      }
    }
  },
  typography: {
    paragraph: {
      lineHeight: '170%'
    }
  },
  components: {
    MuiContainer: {
      defaultProps: {
        maxWidth: 'lg'
      }
    },
    MuiButton: {
      styleOverrides: {
        sizeSmall: {
          borderRadius: 500
        },
        root: {
          borderRadius: 500
        },
        sizeLarge: {
          borderRadius: 500
        }
      }
    }
  }
});