'use client';

import { Button, ButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';
export const UmbrellaButton = styled(Button)<ButtonProps>(({
  theme,
  variant
}) => {
  if (variant === 'outlined') {
    return {
      color: theme.palette.common.black,
      backgroundColor: theme.palette.common.white,
      borderWidth: 2,
      borderColor: theme.palette.common.black,
      '&:hover': {
        borderWidth: 2,
        borderColor: theme.palette.common.black,
        backgroundColor: theme.palette.common.white
      }
    };
  }
  if (variant === 'text') {
    return {
      color: theme.palette.common.black,
      '&:hover': {
        backgroundColor: 'transparent'
      }
    };
  }
  return {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.black,
    '&:hover': {
      backgroundColor: theme.palette.common.black
    }
  };
});