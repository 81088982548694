'use client';

import { PropsWithChildren, useMemo } from 'react';
import { CssBaseline } from '@mui/material';
import { AppRouterCacheProvider } from '@mui/material-nextjs/v15-appRouter';
import { CssVarsTheme, Experimental_CssVarsProvider as CssVarsProvider, experimental_extendTheme as extendTheme } from '@mui/material/styles';
import { marketingThemeOptions } from './marketing-theme';

/**
 * https://mui.com/material-ui/integrations/nextjs/#theming
 */
export function MarketingThemeProvider({
  children
}: PropsWithChildren) {
  const theme = useMemo(() => {
    const extendedTheme = extendTheme(marketingThemeOptions);

    // @ts-expect-error - remove the `dark` color scheme to optimize the HTML size for server-side application
    delete extendedTheme.colorSchemes.dark;
    return (extendedTheme as CssVarsTheme);
  }, []);
  return <AppRouterCacheProvider data-sentry-element="AppRouterCacheProvider" data-sentry-component="MarketingThemeProvider" data-sentry-source-file="MarketingThemeProvider.tsx">
      <CssVarsProvider theme={theme} data-sentry-element="CssVarsProvider" data-sentry-source-file="MarketingThemeProvider.tsx">
        <CssBaseline data-sentry-element="CssBaseline" data-sentry-source-file="MarketingThemeProvider.tsx" />
        {children}
      </CssVarsProvider>
    </AppRouterCacheProvider>;
}