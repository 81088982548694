import { ReactComponent as IconCrmIntentSignals } from '@/app/(index)/_components/SolutionSection/assets/icon-crm-intent-signals.svg';
import { ReactComponent as IconEcommerce } from '@/app/(index)/_components/SolutionSection/assets/icon-ecommerce.svg';
import { ReactComponent as IconMarketingAutomation } from '@/app/(index)/_components/SolutionSection/assets/icon-marketing-automation.svg';
import { ReactComponent as IconMarketplace } from '@/app/(index)/_components/SolutionSection/assets/icon-marketplace.svg';
import { ReactComponent as IconPIM } from '@/app/(index)/_components/SolutionSection/assets/icon-pim.svg';
import { ReactComponent as IconQuotationManagement } from '@/app/(index)/_components/SolutionSection/assets/icon-quotation-management.svg';
import { ReactComponent as IconSalesChannelSyndication } from '@/app/(index)/_components/SolutionSection/assets/icon-sales-channel-syndication.svg';

export const slidesData = [
  {
    order: 1,
    Icon: IconPIM,
    title: 'PIM Syndication',
    href: '/solutions/pim-syndication',
    description:
      'Streamline product information across platforms for consistent and accurate data.',
  },
  {
    order: 3,
    Icon: IconEcommerce,
    title: 'E-Commerce',
    href: '/solutions/e-commerce',
    description:
      'Expand your reach with a seamless online storefront tailored for industrial parts.',
  },
  {
    order: 5,
    Icon: IconQuotationManagement,
    title: 'Quotation Management',
    href: '/solutions/quotation-management',
    description:
      'Speed up quoting and increase accuracy to close deals faster.',
  },
  {
    order: 7,
    Icon: IconMarketingAutomation,
    title: 'Marketing Automation',
    href: '/solutions/marketing-automation',
    description:
      'Engage leads with targeted campaigns that drive conversions effortlessly.',
  },
  {
    order: 2,
    Icon: IconSalesChannelSyndication,
    title: 'Sales Channel Syndication',
    href: '/solutions/sales-channel-syndication',
    description:
      'Maximize visibility by distributing products across multiple sales channels.',
  },
  {
    order: 4,
    Icon: IconCrmIntentSignals,
    title: 'CRM & Intent Signals',
    href: '/solutions/crm-intent-signals',
    description:
      'Gain insights on customer behavior to personalize outreach and boost retention.',
  },
];
export const navOnlySlides = [
  {
    order: 6,
    Icon: IconMarketplace,
    href: '/solutions/marketplace',
    title: 'Get Discovered on the\n' + 'Quotebeam Marketplace',
    description: 'Expand your reach and sell surplus parts with ease.',
  },
];

export const footerOnlySlides = [
  {
    order: 6,
    Icon: IconMarketplace,
    href: '/solutions/marketplace',
    title: 'Quotebeam Marketplace',
    description: 'Expand your reach and sell surplus parts with ease.',
  },
];
