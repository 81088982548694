'use client';

import { Box, Container, Unstable_Grid2 as Grid2, Typography, Link, LinkProps, Stack, IconButton } from '@mui/material';
import { slidesData, footerOnlySlides } from '@/app/(index)/_components/UmbrellaIntro/umbrellaIntro.data';
import { UmbrellaButton } from '@/app/(index)/_components/_components/UmbrellaButton';
import { ReactComponent as IconLinkedIn } from './assets/icon-linkedin.svg';
import { ReactComponent as Logo } from './assets/logo-monochrome.svg';
const MenuLink = (props: LinkProps) => <Link color="inherit" variant="bodyLarge" {...props} sx={[{
  fontWeight: 500
}]} data-sentry-element="Link" data-sentry-component="MenuLink" data-sentry-source-file="Footer.tsx" />;
export const Footer = () => {
  const extraFooterDetails = <>
      <UmbrellaButton variant="outlined" size="small" sx={{
      px: 2,
      mt: {
        xs: 0,
        lg: 9.5
      }
    }} href="/get-a-demo">
        Talk to Sales
      </UmbrellaButton>
      <Stack direction="row" sx={{
      alignItems: 'center',
      gap: 3,
      mt: 1.5,
      mb: {
        xs: 3,
        lg: 5
      }
    }}>
        <Typography variant="bodyMedium" color="inherit">
          follow us on
        </Typography>
        <IconButton href="https://www.linkedin.com/company/quotebeam" target="_blank">
          <IconLinkedIn />
        </IconButton>
      </Stack>
      <Typography variant="bodyMedium" color="inherit">
        Copyright &#169; 2015-{new Date().getFullYear()} Quotebeam
      </Typography>
    </>;
  return <Box sx={{
    bgcolor: 'common.black',
    py: {
      xs: 3,
      lg: 6
    },
    color: 'common.white'
  }} data-sentry-element="Box" data-sentry-component="Footer" data-sentry-source-file="Footer.tsx">
      <Container data-sentry-element="Container" data-sentry-source-file="Footer.tsx">
        <Grid2 container columnSpacing={3} rowSpacing={{
        xs: 4,
        lg: 0
      }} data-sentry-element="Grid2" data-sentry-source-file="Footer.tsx">
          <Grid2 xs={12} md={12} lg={3} data-sentry-element="Grid2" data-sentry-source-file="Footer.tsx">
            <Link href="/" title="Go to Quotebeam homepage" sx={{
            height: 56,
            display: 'flex',
            mb: 2.625
          }} data-sentry-element="Link" data-sentry-source-file="Footer.tsx">
              <Logo height="100%" data-sentry-element="Logo" data-sentry-source-file="Footer.tsx" />
            </Link>
            <Typography variant="bodySmall" color="inherit" data-sentry-element="Typography" data-sentry-source-file="Footer.tsx">
              Quotebeam is a platform for industrial distributors that
              streamlines workflows and strengthens customer relationships
              through tools like collaboration and automation.
            </Typography>
            <Box sx={{
            display: {
              xs: 'none',
              lg: 'block'
            }
          }} data-sentry-element="Box" data-sentry-source-file="Footer.tsx">
              {extraFooterDetails}
            </Box>
          </Grid2>
          <Grid2 xs={12} md={4} lg={3} data-sentry-element="Grid2" data-sentry-source-file="Footer.tsx">
            <Typography variant="bodyLargePrimary" color="inherit" sx={{
            mb: 3
          }} data-sentry-element="Typography" data-sentry-source-file="Footer.tsx">
              Solutions
            </Typography>
            <Stack sx={{
            rowGap: 2
          }} data-sentry-element="Stack" data-sentry-source-file="Footer.tsx">
              {[...slidesData, ...footerOnlySlides].map(slide => <MenuLink key={slide.title} href={slide.href} title={slide.description}>
                  {slide.title}
                </MenuLink>)}
            </Stack>
          </Grid2>
          <Grid2 xs={12} md={4} lg={3} data-sentry-element="Grid2" data-sentry-source-file="Footer.tsx">
            <Stack rowGap={2} data-sentry-element="Stack" data-sentry-source-file="Footer.tsx">
              <MenuLink href={process.env.WEB_HOST} data-sentry-element="MenuLink" data-sentry-source-file="Footer.tsx">Marketplace</MenuLink>
              <MenuLink href={`${process.env.WEB_HOST}/about-us`} data-sentry-element="MenuLink" data-sentry-source-file="Footer.tsx">
                About Us
              </MenuLink>
              <MenuLink href={`${process.env.WEB_HOST}/blog`} data-sentry-element="MenuLink" data-sentry-source-file="Footer.tsx">Blog</MenuLink>
            </Stack>
          </Grid2>
          <Grid2 xs={12} md={4} lg={3} data-sentry-element="Grid2" data-sentry-source-file="Footer.tsx">
            <Typography variant="bodyLargePrimary" color="inherit" sx={{
            mb: 3
          }} data-sentry-element="Typography" data-sentry-source-file="Footer.tsx">
              Contact
            </Typography>
            <Stack sx={{
            rowGap: 2
          }} data-sentry-element="Stack" data-sentry-source-file="Footer.tsx">
              <Typography variant="bodyLarge" color="inherit" sx={{
              fontWeight: 500
            }} data-sentry-element="Typography" data-sentry-source-file="Footer.tsx">
                Sales:{' '}
                <MenuLink href="mailto:sales@quotebeam.com" underline="always" data-sentry-element="MenuLink" data-sentry-source-file="Footer.tsx">
                  sales@quotebeam.com
                </MenuLink>
              </Typography>
              <Typography variant="bodyLarge" color="inherit" sx={{
              fontWeight: 500
            }} data-sentry-element="Typography" data-sentry-source-file="Footer.tsx">
                Customer Support:{' '}
                <MenuLink href="mailto:customer@quotebeam.com" underline="always" data-sentry-element="MenuLink" data-sentry-source-file="Footer.tsx">
                  customer@quotebeam.com
                </MenuLink>
              </Typography>
              <Box sx={{
              display: {
                xs: 'block',
                lg: 'none'
              }
            }} data-sentry-element="Box" data-sentry-source-file="Footer.tsx">
                {extraFooterDetails}
              </Box>
            </Stack>
          </Grid2>
        </Grid2>
      </Container>
    </Box>;
};