import { PropsWithChildren } from 'react';
import { PopperPlacementType, ClickAwayListener, Paper, Popper, Grow, Collapse } from '@mui/material';
import { useIsMobile } from '@qb/frontend/hooks/mediaQueries';
type PopperWrapperProps = PropsWithChildren<{
  anchorEl: HTMLElement | null;
  setAnchorEl: (value: HTMLElement | null) => void;
  placement?: PopperPlacementType;
}>;
export const PopperWrapper = ({
  anchorEl,
  setAnchorEl,
  children,
  placement = 'bottom-end'
}: PopperWrapperProps) => {
  const isMobile = useIsMobile();
  if (isMobile) {
    return <Collapse in={Boolean(anchorEl)} timeout="auto" unmountOnExit>
        {children}
      </Collapse>;
  }
  return <Popper sx={{
    zIndex: 'appBar'
  }} open={Boolean(anchorEl)} anchorEl={anchorEl} transition placement={placement} data-sentry-element="Popper" data-sentry-component="PopperWrapper" data-sentry-source-file="PopperWrapper.tsx">
      {({
      TransitionProps
    }) => <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
          <Grow {...TransitionProps}>
            <Paper>{children}</Paper>
          </Grow>
        </ClickAwayListener>}
    </Popper>;
};